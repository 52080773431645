<template>
  <div id="view-review">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Review</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="review_id"
                filled
                label="Review Id"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="name"
                filled
                label="Reviewer Name"
                type="text"
                disabled
              ></v-text-field>
              <v-textarea
                v-model="review_content"
                auto-grow
                filled
                label="Review Body"
                rows="1"
                disabled
              ></v-textarea>
              <v-text-field
                v-model="review_website"
                auto-grow
                filled
                label="Review Website"
                rows="1"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="business_logo"
                filled
                label="Business Logo URL (Optional)"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="rating"
                filled
                label="Rating"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="approved"
                filled
                label="Approved"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="added"
                filled
                label="Added Date"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="added_by"
                filled
                label="Added By"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="edited"
                filled
                label="Last Edited"
                type="text"
                disabled
              ></v-text-field>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" v-bind:to="{ name: 'edit-business-review', params: { review_id: review_id }}">Edit
              </v-btn>
              <v-btn color="info" to="/">Back
              </v-btn>
              <v-btn color="danger" v-on:click="deleteReview">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import db from '../config/firebaseInit'

export default {
  name: 'view-business-review',
  data () {
    return {
      review_id: null,
      name: null,
      review_content: null,
      rating: null,
      approved: null,
      added: null,
      edited: null,
      added_by: null,
      review_website: null,
      business_logo: null
    }
  },
  beforeRouteEnter (to, from, next) {
    db
      .collection('business-reviews')
      .where('review_id', '==', to.params.review_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.review_id = doc.data().review_id
            vm.name = doc.data().name
            vm.review_content = doc.data().review_content
            vm.rating = doc.data().rating
            vm.approved = doc.data().approved
            vm.added = doc.data().added.toDate().toDateString()
            vm.edited = doc.data().edited.toDate().toDateString()
            vm.added_by = doc.data().added_by
            vm.review_website = doc.data().website
            vm.business_logo = doc.data().business_logo
          })
        })
      })
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    fetchData () {
      db
        .collection('business-reviews')
        .where('review_id', '==', this.$route.params.review_id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.review_id = doc.data().review_id
            this.name = doc.data().name
            this.review_content = doc.data().review_content
            this.rating = doc.data().rating
            this.approved = doc.data().approved
            this.added = doc.data().added.toDate().toDateString()
            this.added = doc.data().added.toDate().toDateString()
            this.edited = doc.data().edited.toDate().toDateString()
            this.added_by = doc.data().added_by
            this.review_website = doc.data().website
            this.business_logo = doc.data().business_logo
          })
        })
    },
    deleteReview () {
      if (confirm('Are you sure?')) {
        db
          .collection('business-reviews')
          .where('review_id', '==', this.$route.params.review_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.delete()
              this.$router.push('/reviews/business')
            })
          })
      }
    }
  }
}
</script>
