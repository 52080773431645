<template>
  <div id="new-review">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Edit review</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="name"
                filled
                label="Reviewer Name"
                type="text"
              ></v-text-field>
              <v-textarea
                v-model="review_content"
                auto-grow
                filled
                label="Review Body"
                rows="1"
              ></v-textarea>
              <v-text-field
                v-model="website"
                auto-grow
                filled
                label="Review Website"
                rows="1"
              ></v-text-field>
              <v-text-field
                v-model="business_logo"
                filled
                label="Business Logo URL (Optional)"
                type="text"
              ></v-text-field>
              <v-select
                :items="rating_options"
                v-model="rating"
                filled
                label="Customer Rating"
              ></v-select>
              <v-select
                :items="approved_options"
                v-model="approved"
                filled
                label="Approved"
              ></v-select>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="primary"
                v-on:click="updateReview"
              >Submit Edited Review
              </v-btn>
              <v-btn
                color="primary"
                text
                v-bind:to="{ name: 'view-business-review', params: { review_id: review_id }}"
              >Back
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import db from '../config/firebaseInit'

export default {
  name: 'edit-business-review',
  data () {
    return {
      review_id: null,
      name: null,
      review_content: null,
      website: null,
      rating: null,
      approved: null,
      approved_options: [
        true,
        false
      ],
      rating_options: [
        0,
        1,
        2,
        3,
        4,
        5
      ],
      business_logo: null
    }
  },
  beforeRouteEnter (to, from, next) {
    db.collection('business-reviews').where('review_id', '==', to.params.review_id).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        next(vm => {
          vm.review_id = doc.data().review_id
          vm.name = doc.data().name
          vm.review_content = doc.data().review_content
          vm.website = doc.data().website
          vm.rating = doc.data().rating
          vm.approved = doc.data().approved
          vm.business_logo = doc.data().business_logo
          if (vm.rating !== 0 || vm.rating !== 1 || vm.rating !== 2 || vm.rating !== 3 || vm.rating !== 4 || vm.rating !== 5) {
            vm.rating = 5
          }
        })
      })
    })
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      db.collection('business-reviews').where('review_id', '==', this.$route.params.review_id).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.review_id = doc.data().review_id
          this.name = doc.data().name
          this.review_content = doc.data().review_content
          this.website = doc.data().website
          this.rating = doc.data().rating
          this.approved = doc.data().approved
          this.business_logo = doc.data().business_logo
        })
      })
    },
    updateReview () {
      if (this.business_logo === null || this.business_logo === '') {
        this.business_logo = 'https://d1dvc21uhnjnez.cloudfront.net/customer/vlf/image/anonymous_testimonial.png'
      }
      db.collection('business-reviews').where('review_id', '==', this.$route.params.review_id).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({
            review_id: this.review_id,
            name: this.name,
            review_content: this.review_content,
            website: this.website,
            rating: this.rating,
            approved: this.approved,
            edited: new Date(),
            business_logo: this.business_logo
          })
            .then(() => {
              this.$router.push({ name: 'view-business-review', params: { review_id: this.review_id } })
            })
        })
      })
    }
  }
}
</script>
