<template>
  <div id="home">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-layout row>
            <v-flex xs12 sm6 offset-sm3>
              <v-card>
                <v-list subheader>
                  <v-subheader>Van Load Fife Administration Panel Home</v-subheader>
                  <v-list-item
                    to="/reviews/customer"
                  >
                    <v-list-item-avatar>
                      <v-icon>rate_review</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Customer Reviews</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>arrow_right_alt</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    to="/reviews/business"
                  >
                    <v-list-item-avatar>
                      <v-icon>rate_review</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Business Reviews</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>arrow_right_alt</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    to="/gallery/home"
                  >
                    <v-list-item-avatar>
                      <v-icon>image</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>Gallery</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon>arrow_right_alt</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style scoped>

</style>
