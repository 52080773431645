<template>
  <div id="home">
    <v-content>
      <v-container fluid fill-height>
        <v-layout>
          <v-flex xs12 sm6 offset-sm3>
              <gallery-card v-for="image in images" v-bind:image_id="image.image_id"
                            v-bind:key="image.image_id"
                            v-bind:human_id="image.human_id" v-bind:caption="image.caption"
                            v-bind:image_url="image.url"></gallery-card>
              <v-divider></v-divider>
              <v-list subheader>
                <v-container>
                  <v-btn block color="primary" to="/gallery/add">Add a new image</v-btn>
                </v-container>
              </v-list>
            </v-flex>
          </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import db from '../config/firebaseInit'
import GalleryCard from '../components/GalleryCard'

export default {
  name: 'home',
  components: { GalleryCard },
  data () {
    return {
      images: [],
      loading: true
    }
  },
  created () {
    db
      .collection('gallery-images')
      .orderBy('date_added')
      .get()
      .then(querySnapshot => {
        this.loading = false
        let counter = 1
        querySnapshot.forEach(doc => {
          const data = {
            id: doc.id,
            image_id: doc.data().image_id,
            url: doc.data().thumbnail,
            caption: doc.data().caption,
            date_added: doc.data().date_added,
            human_id: counter
          }
          this.images.push(data)
          counter++
        })
        this.images = this.images.reverse()
      })
  }
}
</script>

<style scoped>
  a {
    color: #FFF;
  }

  a:hover {
    color: #00F
  }
</style>
