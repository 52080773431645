<template>
<div>
   <v-card>
        <v-img
          :src="image_url"
          aspect-ratio="2.75"
        ></v-img>

        <v-card-title primary-title>
          <div>
            <h3 class="headline mb-0">{{this.caption}}</h3>
            <div> {{ this.image_id }} </div>
          </div>
        </v-card-title>

        <v-card-actions>
          <v-btn color="primary" v-bind:to="{ name: 'edit-image', params: { image_id: this.image_id }}">Edit</v-btn>
          <v-btn color="primary" v-bind:to="{ name: 'view-image', params: { image_id: this.image_id }}">View</v-btn>
        </v-card-actions>
      </v-card>
      <v-divider></v-divider>
</div>
</template>

<script>
export default {
  name: 'GalleryCard',
  props: {
    'image_id': {
      default: null,
      type: String
    },
    'human_id': {
      default: null,
      type: Number
    },
    'caption': {
      default: null,
      type: String
    },
    'image_url': {
      default: null,
      type: String
    }
  }
}
</script>

<style scoped>

</style>
