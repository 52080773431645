<template>
  <div id="add-image">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Add new image</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <input
                type="file"
                id="imageUploadInput"
                @change="onFileSelected"
                ref="imageInput"
              >
              <!--<v-btn-->
              <!--color="primary"-->
              <!--@click="$refs.imageInput.click()"-->
              <!--&gt;-->
              <!--Select image to upload-->
              <!--</v-btn>-->
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                :loading="loading"
                :disabled="loading"
                color="primary"
                class="white--text"
                v-on:click="onUpload"
              >{{ image_uploading_button_text }}
                <v-icon right dark>cloud_upload</v-icon>
              </v-btn>
              <v-btn text color="info" to="/gallery/home">Back</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-content>
    <v-snackbar
      v-model="snackbar"
      :bottom="y === 'bottom'"
      :left="x === 'left'"
      :multi-line="mode === 'multi-line'"
      :right="x === 'right'"
      :timeout="timeout"
      :top="y === 'top'"
      :vertical="mode === 'vertical'"
    >
      {{ snackbar_text }}
      <v-btn
        color="pink"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import firebase from 'firebase/compat/app'
  import axios from 'axios'

  export default {
    name: 'AddGalleryImage',
    data() {
      return {
        selectedFile: null,
        loader: null,
        loading: false,
        snackbar: false,
        y: 'bottom',
        x: null,
        mode: '',
        timeout: 2500,
        snackbar_text: 'Image added to gallery',
        image_uploading_button_text: 'Add Image'
      }
    },
    methods: {
      onFileSelected(event) {
        this.selectedFile = event.target.files[0]
      },
      async onUpload() {
        this.loader = 'loading'
        this.image_uploading_button_text = 'Uploading image please wait'
        if (this.selectedFile === null) {
          this.snackbar_text = 'No image selected'
          this.snackbar = true
        }
        const idToken = await firebase.auth().currentUser.getIdToken(true)
        const fd = new FormData()
        fd.append('image', this.selectedFile, this.selectedFile.name)
        const config = {
          headers: {'Authorization': 'Bearer ' + idToken}
        }
        axios.post('https://us-central1-vanload-fife.cloudfunctions.net/app/gallery', fd, config).then(res => {
          if (res.status === 201 || res.status === 200) {
            this.loading = null
            this.snackbar_text = 'Image successfully uploaded'
            this.snackbar = true
            this.selectedFile = null
            this.image_uploading_button_text = 'Add Image'
            document.getElementById('imageUploadInput').value = ''
          }
        }).catch(err => {
          this.snackbar_text = 'Error uploading image'
          this.snackbar = true
          alert("Error: " + err);
        })
      }
    },
    watch: {
      loader() {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 3000)

        this.loader = null
      }
    }
  }
</script>

<style scoped>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }

  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
