<template>
  <div id="new-image">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Edit image data</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="caption"
                box
                label="Image Caption"
                type="text"
              ></v-text-field>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="primary"
                v-on:click="updateImage"
              >Submit Edited Image Data
              </v-btn>
              <v-btn
                color="primary"
                flat
                v-bind:to="{ name: 'view-image', params: { image_id: image_id }}"
              >Back
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import db from '../config/firebaseInit'

export default {
  name: 'edit-image',
  data () {
    return {
      image_id: null,
      caption: null
    }
  },
  beforeRouteEnter (to, from, next) {
    db.collection('gallery-images').where('image_id', '==', to.params.image_id).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        next(vm => {
          vm.image_id = doc.data().image_id
          vm.caption = doc.data().caption
        })
      })
    })
  },
  watch: {
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      db.collection('gallery-images').where('image_id', '==', this.$route.params.image_id).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          this.image_id = doc.data().image_id
          this.caption = doc.data().caption
        })
      })
    },
    updateImage () {
      db.collection('gallery-images').where('image_id', '==', this.$route.params.image_id).get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          doc.ref.update({
            image_id: this.image_id,
            caption: this.caption
          })
            .then(() => {
              this.$router.push({ name: 'view-image', params: { image_id: this.image_id } })
            })
        })
      })
    }
  }
}
</script>
