<template>
  <div id="home">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-layout row>
            <v-flex xs12 sm6 offset-sm3>
              <v-card>
                <v-list subheader>
                  <v-subheader>Reviews</v-subheader>
                  <business-reviews-list-tile v-for="review in reviews" v-bind:review_id="review.review_id"
                                     v-bind:key="review.review_id"
                                     v-bind:human_id="review.human_id"
                                     v-bind:reviewer_name="review.name"></business-reviews-list-tile>
                </v-list>
                <v-divider></v-divider>
                <v-list subheader>
                  <v-container>
                    <v-btn block color="primary" to="/reviews/business/add">Add a new review</v-btn>
                  </v-container>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-content>
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-layout row>
            <v-flex xs12 sm6 offset-sm3>
              <v-card>
                <v-list subheader>
                  <v-subheader>Unapproved Reviews</v-subheader>
                  <business-reviews-list-tile v-for="review in unapproved_reviews" v-bind:review_id="review.review_id"
                                     v-bind:key="review.review_id"
                                     v-bind:human_id="review.human_id"
                                     v-bind:reviewer_name="review.name"></business-reviews-list-tile>
                </v-list>
              </v-card>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import db from '../config/firebaseInit'
import BusinessReviewsListTile from '../components/BusinessReviewsListTile'

export default {
  name: 'home',
  components: { BusinessReviewsListTile },
  data () {
    return {
      reviews: [],
      unapproved_reviews: [],
      loading: true
    }
  },
  created () {
    db
      .collection('business-reviews')
      .orderBy('rating')
      .get()
      .then(querySnapshot => {
        this.loading = false
        let approvedCounter = 1
        let unapprovedCounter = 1
        querySnapshot.forEach(doc => {
          const data = {
            id: doc.id,
            review_id: doc.data().review_id,
            name: doc.data().name,
            review_content: doc.data().review_content,
            rating: doc.data().rating,
            human_id: approvedCounter,
            approved: doc.data().approved
          }
          if (data.approved) {
            data.human_id = approvedCounter
            this.reviews.push(data)
            approvedCounter++
          } else {
            data.human_id = unapprovedCounter
            this.unapproved_reviews.push(data)
            unapprovedCounter++
          }
        })
      })
  }
}
</script>

<style scoped>
  a {
    color: #FFF;
  }

  a:hover {
    color: #00F
  }
</style>
