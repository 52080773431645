import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import CustomerReviewsDashboard from '@/views/CustomerReviewsDashboard'
import ViewReview from '@/views/ViewReview'
import AddReview from '@/views/AddReview'
import EditReview from '@/views/EditReview'
import GalleryDashboard from '@/views/GalleryDashboard'
import AddGalleryImage from '@/views/AddGalleryImage'
import ViewImage from '@//views/ViewImage'
import EditImage from '@/views/EditImage'
import BusinessReviewsDashboard from '@/views/BusinessReviewsDashboard'
import AddBusinessReview from '@/views/AddBusinessReview'
import ViewBusinessReview from '@/views/ViewBusinessReview'
import EditBusinessReview from '@/views/EditBusinessReview'
import Login from '@/views/Login'
import firebase from 'firebase/compat/app'

Vue.use(Router)

let router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/reviews/customer',
      name: 'CustomerReviewsDashboard',
      component: CustomerReviewsDashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: '/add',
      name: 'add-review',
      component: AddReview,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/edit/:review_id',
      name: 'edit-review',
      component: EditReview,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/:review_id',
      name: 'view-review',
      component: ViewReview,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/gallery/home',
      name: 'gallery-home',
      component: GalleryDashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/gallery/view/:image_id',
      name: 'view-image',
      component: ViewImage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/gallery/edit/:image_id',
      name: 'edit-image',
      component: EditImage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/gallery/add',
      name: 'add-image',
      component: AddGalleryImage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/reviews/business',
      name: 'business-reviews',
      component: BusinessReviewsDashboard,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/reviews/business/add',
      name: 'add-business-review',
      component: AddBusinessReview,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/reviews/business/view/:review_id',
      name: 'view-business-review',
      component: ViewBusinessReview,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/reviews/business/edit/:review_id',
      name: 'edit-business-review',
      component: EditBusinessReview,
      meta: {
        requiresAuth: true
      }
    }
  ]
})

// Nav Guard
router.beforeEach((to, from, next) => {
  // Check for requiresAuth guard
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if NO logged user
    if (!firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    // Check if NO logged user
    if (firebase.auth().currentUser) {
      // Go to login
      next({
        path: '/',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      // Proceed to route
      next()
    }
  } else {
    // Proceed to route
    next()
  }
})

export default router
