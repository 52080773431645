<template>
  <div id="add-review">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Add new review</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="name"
                filled
                label="Reviewer Name"
                type="text"
              ></v-text-field>
              <v-textarea
                v-model="review_content"
                auto-grow
                filled
                label="Review Body"
                rows="1"
              ></v-textarea>
              <v-select
                :items="rating_options"
                v-model="rating"
                filled
                label="Customer Rating"
              ></v-select>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                color="primary"
                v-on:click="saveReview"
              >Add Review
              </v-btn>
              <v-btn flat color="info" to="/">Back</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import db from '../config/firebaseInit'
import firebase from 'firebase/compat/app'

require('firebase/compat/auth')
export default {
  name: 'add-review',
  data () {
    return {
      review_id: null,
      name: null,
      review_content: null,
      rating: null,
      rating_options: [
        0,
        1,
        2,
        3,
        4,
        5
      ]
    }
  },
  methods: {
    saveReview () {
      const ref = db.collection('reviews').doc()
      db.collection('reviews').add({
        review_id: ref.id,
        name: this.name,
        review_content: this.review_content,
        rating: this.rating,
        approved: true,
        added: new Date(),
        edited: new Date(),
        added_by: firebase.auth().currentUser.email
      })
        .then(() => {
          this.$router.push('/reviews/customer')
        })
        .catch(error => {
          alert("Error adding review: " + error)
        })
    }
  }
}
</script>
