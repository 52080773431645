<template>
  <div id="view-review">
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-card
            class="mx-auto"
            style="min-width: 60vw;"
          >
            <v-card-title primary-title>
              <div>
                <h3 class="headline mb-0">Review</h3>
              </div>
            </v-card-title>
            <v-form
              ref="form"
              class="pa-3 pt-4"
            >
              <v-text-field
                v-model="image_id"
                filled
                label="Image Id"
                type="text"
                disabled
              ></v-text-field>
              <v-text-field
                v-model="url"
                filled
                label="Image URL"
                type="text"
                disabled
              ></v-text-field>
              <v-textarea
                v-model="date_added"
                auto-grow
                filled
                label="Date added"
                rows="1"
                disabled
              ></v-textarea>
              <v-text-field
                v-model="caption"
                filled
                label="Caption"
                type="text"
                disabled
              ></v-text-field>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" v-bind:to="{ name: 'edit-image', params: { image_id: this.image_id }}">Edit
              </v-btn>
              <v-btn color="info" to="/gallery/home">Back
              </v-btn>
              <v-btn color="danger" v-on:click="deleteImage">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-layout>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import db from '../config/firebaseInit'

export default {
  name: 'view-review',
  data () {
    return {
      image_id: null,
      url: null,
      caption: null,
      date_added: null
    }
  },
  beforeRouteEnter (to, from, next) {
    db
      .collection('gallery-images')
      .where('image_id', '==', to.params.image_id)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          next(vm => {
            vm.image_id = doc.data().image_id
            vm.url = doc.data().path
            vm.caption = doc.data().caption
            vm.date_added = doc.data().date_added.toDate().toDateString()
          })
        })
      })
  },
  watch: {
    $route: 'fetchData'
  },
  methods: {
    fetchData () {
      db
        .collection('gallery-images')
        .where('image_id', '==', this.$route.params.image_id)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            this.image_id = doc.data().image_id
            this.url = doc.data().path
            this.caption = doc.data().caption
            this.date_added = doc.data().date_added.toDate().toDateString()
          })
        })
    },
    deleteImage () {
      if (confirm('Are you sure?')) {
        db
          .collection('gallery-images')
          .where('image_id', '==', this.$route.params.image_id)
          .get()
          .then(querySnapshot => {
            querySnapshot.forEach(doc => {
              doc.ref.delete()
              this.$router.push('/gallery/home')
            })
          })
      }
    }
  }
}
</script>
