<template>
  <v-list-item
    v-bind:to="{ name: 'view-business-review', params: { review_id: this.review_id }}"
  >
    <v-list-item-avatar>
      {{this.human_id}}.
    </v-list-item-avatar>

    <v-list-item-content>
      <v-list-item-title v-html="this.reviewer_name"></v-list-item-title>
    </v-list-item-content>

    <v-list-item-action>
      <font-awesome-icon icon="edit"/>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  name: 'BusinessReviewsListTile',
  props: {
    'review_id': {
      default: null,
      type: String
    },
    'human_id': {
      default: null,
      type: Number
    },
    'reviewer_name': {
      default: null,
      type: String
    }
  }
}
</script>

<style scoped>

</style>
