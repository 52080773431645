<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div id="nav" class="nav-wrapper">
    <v-app-bar
      color="primary"
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{title}}</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      clipped
      app
    >
      <v-list>
        <v-list-item @click.stop="left = !left" v-if="isLoggedIn" v-on:click="routeToHome">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Van Load Fife
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop="left = !left" v-if="!isLoggedIn" v-on:click="routeToLogin">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Van Load Fife
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item @click.stop="left = !left" v-if="isLoggedIn">
          <v-list-item-action>
            <v-icon>person</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{currentUser}}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group prepend-icon="rate_review" v-if="isLoggedIn">
          <template v-slot:activator>
            <v-list-item v-if="isLoggedIn">
              <v-list-item-content>
                <v-list-item-title>
                  Reviews
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        <v-list-item @click.stop="left = !left" v-if="isLoggedIn" v-on:click="routeToCustomersDash">
        <v-list-item-action>
          <v-icon>dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            Customer Reviews
          </v-list-item-title>
        </v-list-item-content>
        </v-list-item>
          <v-list-item @click.stop="left = !left" v-if="isLoggedIn" v-on:click="routeToBusinessDash">
            <v-list-item-action>
              <v-icon>dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Business Reviews
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        <v-list-item @click.stop="left = !left" v-if="isLoggedIn" v-on:click="routeToAdd">
          <v-list-item-action>
            <v-icon>add</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Add Review
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
          <v-list-item @click.stop="left = !left" v-if="isLoggedIn" v-on:click="routeToBusinessAdd">
            <v-list-item-action>
              <v-icon>add</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Add Business Review
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-group prepend-icon="image" v-if="isLoggedIn">
          <template v-slot:activator>
            <v-list-item v-if="isLoggedIn">
              <v-list-item-content>
                <v-list-item-title>
                  Gallery
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item @click.stop="left = !left" v-if="isLoggedIn" v-on:click="routeToGalleryDash">
            <v-list-item-action>
              <v-icon>dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Dashboard
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="left = !left" v-if="isLoggedIn" v-on:click="routeToGalleryAdd">
            <v-list-item-action>
              <v-icon>add</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Add Image
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item @click.stop="left = !left" v-if="isLoggedIn" v-on:click="logout">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click.stop="left = !left" v-if="!isLoggedIn" v-on:click="routeToLogin">
          <v-list-item-action>
            <v-icon>lock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              Login
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import firebase from 'firebase/compat/app'

require('firebase/compat/auth')

export default {
  name: 'navbar',
  data () {
    return {
      isLoggedIn: false,
      currentUser: false,
      drawer: null,
      left: false
    }
  },
  props: {
    'title': {
      default: 'Van Load Fife',
      type: String
    }
  },
  created () {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true
      this.currentUser = firebase.auth().currentUser.email
      // firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
      //   console.log(idToken)
      // }).catch(function (error) {
      //   console.log(error)
      // })
    }
  },
  methods: {
    routeToHome: function () {
      this.$router.push({ path: '/' })
    },
    routeToCustomersDash: function () {
      this.$router.push({ path: '/reviews/customer' })
    },
    routeToBusinessDash: function () {
      this.$router.push({ path: '/reviews/business' })
    },
    routeToAdd: function () {
      this.$router.push({ path: '/add' })
    },
    routeToBusinessAdd: function () {
      this.$router.push({ path: '/reviews/business/add' })
    },
    routeToGalleryDash: function () {
      this.$router.push({ path: '/gallery/home' })
    },
    routeToGalleryAdd: function () {
      this.$router.push({ path: '/gallery/add' })
    },
    routeToLogin: function () {
      this.$router.push({ path: '/login' })
    },
    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({ path: '/login' })
          location.reload()
        })
    }
  }
}
</script>

<style scoped>
  .nav-wrapper {
    margin-bottom: 20px;
  }
</style>
